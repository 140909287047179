/* index.css */

/* Define CSS variables for light theme */
:root {
  --background-color: #f0f0f0; /* Background color */
  --text-color: #333; /* Text color */
  --link-color: #1a0dab; /* Link color for light theme */
  --header-background-color: #f2f2f2; /* Background color for table headers */
  --list-background-color: #fff; /* Background color for list items */
  --list-box-shadow: rgba(0, 0, 0, 0.1); /* Box shadow for list items */
  --border-color: black; /* Border color for table cells */
  --selected-vehicle-color: #d0e7ff; /* Background color for selected vehicle */
}

/* Define CSS variables for dark theme */
body.dark {
  --background-color: #121212; /* Dark background color */
  --text-color: #e0e0e0; /* Light text color */
  --link-color: #4fc3f7; /* Link color for dark theme */
  --header-background-color: #333; /* Dark background color for table headers */
  --list-background-color: #1e1e1e; /* Dark background color for list items */
  --list-box-shadow: rgba(0, 0, 0, 0.5); /* Darker box shadow for list items */
  --border-color: #444; /* Dark border color for table cells */
  --selected-vehicle-color: #3a6ea5; /* Background color for selected vehicle */
}

/* Global styles for the body element */
body {
  font-family: Arial, sans-serif; /* Set font family */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  background-color: var(--background-color); /* Use background color from variable */
  color: var(--text-color); /* Use text color from variable */
  overflow: hidden; /* Hide overflow for the body */
  height: 100vh; /* Ensure body takes full viewport height */
}

/* Global link styles */
a {
  color: var(--link-color);
  text-decoration: none; /* Optional: remove underline */
}

a:hover {
  text-decoration: underline; /* Optional: add underline on hover */
}

/* Styles for the main App container */
.App {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

/* Styles for the header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  padding: 0 20px;
  background-color: var(--header-background-color);
  color: var(--text-color);
  position: fixed; /* Fix the header position */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1; /* Ensure the header is above other elements */
}

/* Ensure the logo container fits within the header */
.logo-container {
  height: 100%;
  display: flex;
  align-items: center; /* Center the logo vertically within the header */
}

/* Styles for the logo image */
.header .logo {
  height: 100%; /* Make the logo fit the height of the header */
  height: auto; /* Maintain the aspect ratio of the logo */
  max-height: 90px; /* Maximum height for the logo */
  max-width: 600px; /* Maximum width for the logo */
  margin-right: 10px; /* Add some space between logos */
}

/* Styles for the panels container */
.panels {
  display: flex;
  flex: 1;
  margin-top: 10vh; /* Adjust margin-top to account for the fixed header height */
  height: calc(100vh - 10vh); /* Subtract the header height from the total viewport height */
  overflow: hidden; /* Prevent overflow for the main container */
}

/* CSS for making panels scrollable and adjusting layout */
/* Generic panel styling */
.panel {
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 20px; /* Add padding inside the panel */
}

/* Styling for the left panel */
.left-panel {
  flex: 1 1 50%; /* Allow the panel to grow and shrink, occupying 50% of the available space */
  margin-right: 10px; /* Add right margin to separate from the right panel */
  border-right: 1px solid var(--border-color); /* Add a right border with the specified color */
}

/* Styles for the right panel */
.right-panel {
  flex: 1 1 50%; /* Allow the panel to grow and shrink, occupying 50% of the available space */
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Arrange children vertically */
}

.vehicle-detail {
  display: flex; /* Use flexbox layout */
  flex-direction: column; /* Arrange children vertically */
  flex: 1; /* Allow the container to grow and fill available space */
}

.image-container {
  flex: 0 0 200px; /* Fixed height for the image container */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center; /* Center the images horizontally */
  align-items: center; /* Center the images vertically */
  overflow: hidden; /* Hide overflow */
}

.detail-container {
  flex: 1; /* Take the remaining space */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* Styles for h1 elements */
h1 {
  color: var(--text-color); /* Use text color from variable */
}

/* Styles for ul elements */
ul {
  list-style-type: none; /* Remove default list style */
  padding: 0; /* Remove default padding */
}

/* Styles for li elements */
li {
  background-color: var(--list-background-color); /* Use background color from variable */
  margin: 10px 0; /* Add margin between list items */
  padding: 10px; /* Add padding to list items */
  border-radius: 4px; /* Round the corners of list items */
  box-shadow: 0 2px 4px var(--list-box-shadow); /* Add box shadow to list items */
}

/* Styles for table elements */
table {
  width: 100%; /* Make the table take up the full width of its container */
  border-collapse: collapse; /* Collapse borders between table cells */
}

/* Styles for th and td elements */
th, td {
  border: 1px solid var(--border-color); /* Use border color from variable */
  padding: 8px; /* Add padding to table cells */
  text-align: left; /* Left-align text in table cells */
}

/* Styles for th elements */
th {
  background-color: var(--header-background-color); /* Use background color from variable */
}

/* Styles for the filter container */
.filter-container {
  display: flex; /* Use flexbox layout */
  flex-direction: column;
  gap: 1rem; /* Add space between flex items */
  margin-bottom: 1rem; /* Add margin below the filter container */
}

.filter-row {
  display: flex;
  justify-content: space-between; /* Ensure even spacing between filters */
  gap: 1rem;
}

.filter-row > div {
  flex: 1;
  min-width: 100px; /* Adjust as needed */
  display: flex;
  flex-direction: column; /* Ensure label and select are stacked */
}

/* Ensure select elements take full width */
.filter-row select {
  width: 100%;
}

.theme-switch-container {
  display: flex;
  align-items: center; /* Align items vertically centered */
  margin-right: 1rem; /* Add some spacing to the right */
}

/* Dynamic theme styles for header */
.light-theme .header {
  background-color: var(--header-background-color);
  color: var(--text-color);
}

.dark-theme .header {
  background-color: var(--header-background-color);
  color: var(--text-color);
}

/* New styles for horizontal menu */
nav ul {
  display: flex; /* Make the list items display in a row */
  gap: 20px; /* Add space between each menu item */
}

nav a {
  color: inherit; /* Inherit color from parent */
  text-decoration: none; /* Remove underline from links */
}

/* New styles for selected vehicle row */
.selected-vehicle {
  border: 2px solid blue; /* Add a blue border to the selected row */
  background-color: var(--selected-vehicle-color); /* Add a background color to the selected row */
}

/* Styles for the vehicle header container */
.vehicle-list-header {
  display: flex;
  justify-content: space-between; /* Distribute space between vehicle count and pagination */
  align-items: center; /* Align items vertically centered */
  margin-bottom: 1rem; /* Add margin below the vehicle header */
}

/* Style for vehicle count */
.vehicle-count {
  font-size: 1.5em;
  font-weight: bold;
}

/* Pagination controls */
.pagination-controls,
.pagination-controls-bottom {
  display: flex;
  align-items: center; /* Center align items */
}

.pagination-controls button,
.pagination-controls-bottom button {
  margin: 0 0.5rem; /* Add margin around buttons */
}

.pagination-controls span,
.pagination-controls-bottom span {
  margin: 0 0.5rem; /* Add margin around page text */
}

.pagination-controls label {
  margin-right: 0.5rem; /* Add some space between the label and the dropdown */
}

/* Styles for the similar vehicles section */
.similar-vehicles-container {
  margin-top: 20px;
}

.similar-vehicles {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.similar-vehicle-card {
  border: 1px solid var(--border-color);
  padding: 10px;
  width: 200px;
  background-color: var(--list-background-color);
  box-shadow: 0 2px 4px var(--list-box-shadow);
  border-radius: 4px;
}

.similar-vehicle-card img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 10px;
}

.similar-vehicle-info {
  font-size: 0.9em;
}

.similar-vehicle-info h4 {
  margin: 0 0 10px 0;
  font-size: 1.1em;
  color: var(--text-color);
}

.similar-vehicle-info p {
  margin: 5px 0;
  color: var(--text-color);
}

/* Styles for the Load Similar Vehicles button */
.load-similar-button {
  display: inline-block;
  padding: 10px 20px; /* Add padding for better appearance */
  margin: 20px 0; /* Add some margin for spacing */
  background-color: var(--link-color); /* Use link color for the button background */
  color: #fff; /* White text color */
  border: none; /* Remove default border */
  border-radius: 4px; /* Add rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-size: 1em; /* Adjust font size */
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.load-similar-button:hover {
  background-color: darken(var(--link-color), 10%); /* Darken the background color on hover */
}

.load-similar-button:active {
  background-color: darken(var(--link-color), 20%); /* Further darken the background color when active */
}

/* Ensure button styles are adapted for dark theme */
body.dark .load-similar-button {
  background-color: var(--link-color);
}

body.dark .load-similar-button:hover {
  background-color: lighten(var(--link-color), 10%);
}

body.dark .load-similar-button:active {
  background-color: lighten(var(--link-color), 20%);
}
